<template>
  <div
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content word-break">
          <h6 class="modal-header mb-2">
            {{ moveFolderData.status === 'folder' ? 'Move Folder' : 'Move File' }}
          </h6>
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <!-- Breadcrumbs -->
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <span
                    class="cursor-pointer"
                    @click="navigateBreadcrumb(null)"
                  >My Folders</span>
                </li>
                <li
                  v-for="(breadcrumb, index) in displayedBreadcrumbs"
                  :key="index"
                  :class="{ 'breadcrumb-item active': index === displayedBreadcrumbs.length - 1, 'breadcrumb-item': index !== displayedBreadcrumbs.length - 1 }"
                >
                  <span v-if="breadcrumb === '...'">
                    {{ breadcrumb }}
                  </span>
                  <span
                    v-else
                    class="cursor-pointer"
                    @click="navigateBreadcrumb(breadcrumb)"
                  >
                    {{ breadcrumb.folder_name }}
                  </span>
                  <!-- <span v-if="index < displayedBreadcrumbs.length - 1 && displayedBreadcrumbs.length > 1 && displayedBreadcrumbs[displayedBreadcrumbs.length - 1] !== '...'"> / </span> -->
                </li>
                <span
                  v-if="currentPath.length > 6"
                  class="currentPath"
                > / </span>
              </ol>
            </nav>
            <div class="table-responsive mt-2">
              <table class="table table-borderless table-data table-hide-last-column noBorder">
                <tbody>
                  <template v-if="displayedItems.length === 0 && selectedFolder === 'storage'">
                    <tr>
                      <td
                        colspan="1"
                        class="noBorder"
                      >
                        There are no folders to display
                      </td>
                    </tr>
                  </template>
                  <tr v-if="parentFolderId">
                    <div
                      class="defaultStorge cursor-pointer"
                      :class="{ 'folder-item': true, 'active': 'storage' === selectedFolder }"
                      @click="selectFolder('storage')"
                    >
                      <img
                        alt=""
                        src="@/assets/img/icons/icon-folder.svg"
                        class="mr-1"
                      >
                      Storage
                    </div>
                  </tr>
                  <tr
                    v-for="folder in displayedItems"
                    :key="folder.id"
                  >
                    <td
                      class="noBorder"
                      :class="{ 'folder-item': true, 'active': folder === selectedFolder }"
                      @click="selectFolder(folder)"
                      @dblclick="openFolder(folder)"
                    >
                      <!-- <div class="d-flex"> -->
                      <div>
                        <img
                          alt=""
                          src="@/assets/img/icons/icon-folder.svg"
                        >
                        {{ folder.folder_name }}
                      </div>
                      <!-- <div class="ml-auto align-items-center justify-content-center d-flex"> -->
                      <i
                        class="fas fa-chevron-right d-flex ml-auto"
                        @click="openFolder(folder)"
                      />
                      <!-- </div> -->
                      <!-- </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Action Buttons -->
            <div class="text-center mt-3">
              <button
                href="#"
                class="btn btn-outline"
                @click.prevent="$emit('close')"
              >
                Cancel
              </button>
              <button
                :disabled="moveButtonEnabled"
                :class="{ 'disabled-overlay': moveButtonEnabled }"
                href="#"
                class="btn btn-primary ml-2"
                @click.prevent="handleMoveFolder"
              >
                Move
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mainStore} from '@/store/__STORE_main';
import {userStore} from '@/store/__STORE_user';
import {meetingStore} from '@/store/__STORE_meetings';


export default {
  data() {
    return {
      folders: [],
      //   errors: {},
      displayedItems: [],
      currentPath: [],
      moveButtonEnabled: true,
      selectedFolder: null,
    };
  },
  computed: {
    // folders: () => meetingStore.folders,
    userInfo() {
      return userStore.__GETTERuserProfile;
    },
    moveFolderData() {
      return meetingStore.__GETTERshowCloseMoveFolder;
    },
    parentFolderId() {
      return meetingStore.__GETTERparentFolderId;
    },
    displayedBreadcrumbs() {
      const MAX_BREADCRUMB_LENGTH = 4;
      if (this.currentPath.length <= MAX_BREADCRUMB_LENGTH) {
        return this.currentPath;
      } else {
        const displayed = this.currentPath.slice(this.currentPath.length - MAX_BREADCRUMB_LENGTH);
        return ['...', ...displayed];
      }
    },
  },
  created() {
    // eslint-disable-next-line
        $('body').addClass('f-modal-is-open');
    meetingStore.getFoldersList(this.parentFolderId)
        .then((res) => {
          console.log('this.diaplayItem', res);
          this.folders = res.data.folders;
          this.displayedItems = this.folders; // Make a copy of folders array
        })
        .catch((error) => {
          console.error('Error fetching folders:', error);
        });
    console.log('this.diaplayItem', this.displayedItems);
  },
  destroyed() {
    // eslint-disable-next-line
        $('body').removeClass('f-modal-is-open');
  },
  methods: {
    openFolder(item) {
      this.displayedItems = item.subfolders_data || [];
      this.currentPath.push(item);
    },
    selectFolder(folder) {
      console.log('inside');
      this.selectedFolder = folder;
      this.moveButtonEnabled = false;
    },
    navigateBreadcrumb(path) {
      if (path === null) {
        // If null is passed, navigate to the root folder
        this.currentPath = [];
        this.displayedItems = this.folders;
        return;
      }
      // Logic to navigate to a specific breadcrumb folder
      const index = this.currentPath.findIndex((p) => p.id === path.id);
      if (index !== -1) {
        this.currentPath = this.currentPath.slice(0, index + 1);
        this.displayedItems = this.currentPath[this.currentPath.length - 1].subfolders_data || [];
      }
    },
    handleMoveFolder() {
      mainStore.setLoading();
      if (this.moveFolderData.status === 'folder') {
        const payload = {
          source_folder_id: this.moveFolderData.id,
          destination_folder_id: this.selectedFolder === 'storage' ? '' : this.selectedFolder.id,
          user_id: this.userInfo.id,
        };
        meetingStore.moveFolder(payload)
            .then(() => {
              this.$toast.success('Folder moved successfully!');
              this.$emit('close');
            // window.location.reload();
            })
            .catch((err) => {
              const data = err.response.data;
              if (err.response.status === 422) {
                this.errors = data.errors;
                return;
              }
              this.$toast.error('Error');
            });
      } else {
        const payload = {
          recording_file_id: this.moveFolderData.id,
          destination_folder_id: this.selectedFolder === 'storage' ? '' : this.selectedFolder.id,
          user_id: this.userInfo.id,
        };
        meetingStore.moveRecordingFile(payload)
            .then(() => {
              this.$toast.success('File moved successfully!');
              this.$emit('close');
            })
            .catch((err) => {
              this.$toast.error(err.response.data.message);
              if (err.response.status === 422) {
                if (err.response.data.errors) {
                  this.errors = err.response.data.errors;
                } else {
                  this.errors = [];
                }
              }
            });
      }
    },
  },
};
</script>
<style>
/* CSS for folder item */
.folder-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px !important;
}

/* CSS for active (selected) folder item */
.folder-item.active {
  background-color: #ccc;
}

/* CSS for hover effect */
.folder-item:hover {
  background-color: #f0f0f0;
}

/* CSS for active (clicked) folder item */
.folder-item:active {
  background-color: #999;
}
.disabled-overlay {
  background: rgb(201, 201, 201);
  backdrop-filter: blur(2px);
  cursor: not-allowed;  /* Allow clicks to go through to the content */
}
.noBorder {
    border: 0px !important;
    /* padding:0 !important; */
    margin:0 !important;
    border-collapse: collapse;
}
.word-break {
    word-break: break-all;
}
.modal-header {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 20px;
    border-bottom: 0;
}
.defaultStorge {
  padding-left: 5px;
}
</style>
