
import Component, {mixins} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import Gotcha from '@/components/Gotcha.vue';
import ConfirmDeleteRecording from '@/components/ConfirmDeleteRecording.vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import IconPlusPrimary from '@/components/icons/IconPlusPrimary.vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconMove from '@/components/icons/IconMove.vue';
import IconDownload from '@/components/icons/IconDownload.vue';
import StorageState from '@/components_v2/intelligent/personal/__COMP_StorageState.vue';
import {format, addSeconds} from 'date-fns';

import Plan from '@/mixins/Plan';
import Recordings from '@/mixins/Recordings';
import DateFormat from '@/mixins/DateFormat.ts';

import {mainStore} from '@/store/__STORE_main';
import {meetingStore} from '@/store/__STORE_meetings';

@Component({
  components: {
    Gotcha,
    ConfirmDeleteRecording,
    AudioPlayer,
    IconPlusPrimary,
    IconDownload,
    IconEdit,
    IconMove,
    StorageState,
  },
})
/**
 *
 */
export default class AccountRecordings extends mixins(Plan, Recordings, DateFormat) {
  name: 'Recordings and Transcripts';

  tipList = [
    'You will find your recorded meetings here',
    'You can choose storage plans that will persist recordings for 24 hours or forever',
  ];
  isShowGotcha = true;
  isShowConfirmDeleteRecording = false;
  isShowAudioPlay = false;
  storageFetched = false;
  selRecord = {};
  curPage = null;
  stopMadHackz = false;
   currentPath= []; // Current folder path
  createFolderParentId = '';
  perPage=50;

  /**
   * open folder
   * @param {any} item
   */
  openFolder(item) {
    this.createFolderParentId = item.id;
    meetingStore.setCreateFolderParentId(Number(item.id));
    meetingStore.getFolderData(item.id, {perPage: this.perPage, pageNum: this.curPage});
    this.currentPath.push(item);
    this.saveBreadcrumbs();
    this.$emit('currentPathEvent', this.currentPath);
  }


  /**
   * Breadcurms
   */
  navigateToFolder(path) {
    meetingStore.setPageNumber(1);
    if (path === null) {
      meetingStore.setParentPageNumber(1);
      // If null is passed, navigate to the root folder
      meetingStore.setCreateFolderParentId(null);
      this.createFolderParentId = '';
      this.currentPath = [];
      this.$emit('currentPathEvent', this.currentPath);
      localStorage.removeItem('currentPath');
      meetingStore.getRecordingsData();
    }

    // Navigate to a specific folder from the breadcrumb trail
    const index = this.currentPath.findIndex((p) => p.id === path.id);
    if (index !== -1) {
      this.createFolderParentId = path && path.id;
      meetingStore.setCreateFolderParentId(Number(path.id));
      const folderId = this.currentPath[index].id;
      meetingStore.getFolderData(folderId, {perPage: this.perPage, pageNum: this.curPage});
      this.currentPath = this.currentPath.slice(0, index + 1);
      this.$emit('currentPathEvent', this.currentPath);
      this.saveBreadcrumbs();
    }
  }

  /**
   * Store Breadcurms in Localstorage
   */
  saveBreadcrumbs() {
    localStorage.setItem('currentPath', JSON.stringify(this.currentPath));
  }
  /**
   * Retrive Breadcurms in Localstorage
   */
  retrieveBreadcrumbs() {
    if (localStorage.getItem('currentPath')) {
      this.currentPath = JSON.parse(localStorage.getItem('currentPath'));
      const folder = this.currentPath[this.currentPath.length - 1];
      meetingStore.getFolderData(folder.id, {perPage: this.perPage, pageNum: this.curPage});
    } else {
      meetingStore.getRecordingsData();
      localStorage.setItem('currentPath', JSON.stringify([]));
    }
  }
  /**
   * Fires when created
   */
  created() {
    mainStore.setLoading();
    meetingStore.getStorage().then(() => {
      this.storageFetched = true;
      meetingStore.getRecordingsData();
      mainStore.clearStatus();
      this.retrieveBreadcrumbs();
    });
    localStorage.setItem('currentPath', JSON.stringify([]));
    meetingStore.setCreateFolderParentId(null);
  }

  /**
   * recall init table if update
   */
  updated() {
    this.initTable();
  }

  /**
   * set create Folder
   * @param {any} createMe
   */
  createFolder(): void {
    const createMe = {
      value: true,
      id: Number(this.createFolderParentId),
      name: '',
      status: '',
    };
    meetingStore.setShowCloseFolder(createMe);
  }
  /**
   * set move Folder
   * @param {any} curFolder
   */
  moveFolder(curFolder: any): void {
    const moveMe = {
      value: true,
      id: Number(curFolder.id),
      status: 'folder',
    };
    meetingStore.setShowMoveFolder(moveMe);
  }

  /**
   * set deleteable Folder
   * @param {any} curRecord
   */
  confirmDeleteFolder(curRecord): void {
    const deleteMe = {
      value: true,
      id: curRecord.id,
      status: 'folder',
    };
    meetingStore.setShowDeleteRecording(deleteMe);
  }
  /**
   * set move File
   * @param {any} curFile
   */
  moveFile(curFile: any): void {
    const moveMe = {
      value: true,
      id: Number(curFile.id),
      status: 'file',
    };
    meetingStore.setShowMoveFolder(moveMe);
  }

  /**
   * Watches for if the current page of recordings
   * @param {any} val - the recordings list
   */
  @Watch('recordingList')
  updateList(val: any) {
    this.curPage = val.pagination.current_page;
  }

  /**
   * set deleteable Recording
   * @param {any} curRecord
   */
  confirmDelete(curRecord): void {
    const deleteMe = {
      value: true,
      id: Number(curRecord.id),
      status: '',
    };
    meetingStore.setShowDeleteRecording(deleteMe);
  }

  /**
   * set edit File Recording
   * @param {any} curFolder
   */
  confirmEditFolder(curFolder: any): void {
    const editMe = {
      value: true,
      id: curFolder.id,
      name: curFolder.folder_name,
      status: 'edit',
    };
    meetingStore.setShowCloseFolder(editMe);
  }
  /**
   * set edit File Recording
   * @param {any} curFile
   */
  confirmEdit(curFile: any): void {
    const editMe = {
      value: true,
      id: curFile.id,
      // name: this.__MIXINrecordingName(curFile),
      name: curFile.updated_file_name ? curFile.updated_file_name : this.__MIXINrecordingName(curFile),
    };
    meetingStore.setShowEditFileRecording(editMe);
  }
  /**
   * Initialize the table of recordings
   */
  initTable(): void {
    const element = document.querySelectorAll('.tr-has-expandable');

    for (let i = 0; i < element.length; i++) {
      const elem = element[i] as any;
      elem.onclick = function(e: any) {
        const that = this;
        if (!that.classList.contains('is-expanded')) {
          that.querySelector('.td-expand').children[0].classList.remove('fa-chevron-down');
          that.querySelector('.td-expand').children[0].classList.add('fa-chevron-up');
          that.classList.add('is-expanded');
          that.nextElementSibling.children[0].children[0].style.maxHeight = that.nextElementSibling.children[0].children[0].scrollHeight + 'px';
        } else {
          that.querySelector('.td-expand').children[0].classList.remove('fa-chevron-up');
          that.querySelector('.td-expand').children[0].classList.add('fa-chevron-down');
          that.classList.remove('is-expanded');
          that.nextElementSibling.children[0].children[0].style.maxHeight = '';
        }
      };
    }
  }

  /**
   * convert a recordings duration in seconds into a readable format of H:mm:ss
   * @param {number} seconds
   * @return {string}
   */
  convertDuration(seconds: number): string {
    const date = new Date(0);
    date.setHours(0, 0, 0, 0);
    return format(addSeconds(date, seconds), 'H:mm:ss');
  }

  /**
   * Calculate the total amount of space currently used
   * @param {any} curRecord
   * @return {string}
   */
  calcUsage(curRecord): string {
    let storage = 'video ' + this.bytesToMb(curRecord.video_size_in_bytes) + 'MB';

    if (curRecord.audio_size_in_bytes) {
      storage += ', audio ' + this.bytesToMb(curRecord.audio_size_in_bytes) + 'MB';
    }

    return storage;
  }

  /**
   * bytes to Megabytes
   * @param {number} bytes
   * @return {string}
   */
  bytesToMb(bytes: number): string {
    return parseFloat(String(bytes / 1000000)).toFixed(2);
  }

  /**
   * Expand the currect recording for more data
   * @param {any} curRecord
   */
  openRecording(curRecord: any): void {
    const recordingToView = {
      name: this.__MIXINrecordingName(curRecord),
      recording: curRecord,
    };
    meetingStore.openAudioPlayer(recordingToView);
  }

  /**
   * delete a recording
   * @return {Promise<String>}
   */
  async deleteRecording(): Promise<String> {
    if (this.stopMadHackz) {
      return 'plz stop';
    }
    this.stopMadHackz = true;
    await meetingStore.deleteRecording(this.selRecord['id'])
        .then(() => {
          this.$toast.success('Deleted successfully!');
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    this.isShowConfirmDeleteRecording = false;
    this.stopMadHackz = false;
  }

  /**
   * get specific page number from recordings
   * @param {number} perPage - Number of records per page
   * @param {number} pageNum - Page number to navigate to
   */
  go(perPage, pageNum): void {
    if (this.createFolderParentId) {
      meetingStore.setPageNumber(pageNum);
      // meetingStore.getFolderDataTest(Number(this.createFolderParentId), {perPage: perPage, pageNum: 1});
      meetingStore.getFolderData(Number(this.createFolderParentId), {perPage, pageNum});
    } else {
      meetingStore.setParentPageNumber(pageNum);
      meetingStore.getRecordingsData({perPage: perPage, pageNum: pageNum});
    }
  }

  /**
   * get list of recordings
   * @return {any}
   */
  get recordingList(): any {
    return meetingStore.recordings;
  }

  /**
   * get recordings storage plan
   * @return {any}
   */
  get accountStorage(): any {
    return meetingStore.storage;
  }

  /**
   * get if user has active storage add-on
   * @return {Boolean}
   */
  get activeStorageAddon(): Boolean {
    return this.__MIXINhasActiveStorageAddon;
  }
}
