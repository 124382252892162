<template>
  <div
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <!-- <confirm-dialog-icon /> -->

              <h6 class="mb-2">
                Rename File
              </h6>
              <div
                class="form-group mt-1 mb-2"
                :class="{'form-error': errors.file}"
              >
                <input
                  id="file-name"
                  v-model="file"
                  class="form-control modal-input"
                  type="text"
                  placeholder="Enter File Name"
                >
                <div
                  v-for="(error, index) in errors.file"
                  :key="index"
                  class="form-error-message"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="text-center">
              <a
                href="#"
                class="btn btn-outline"
                @click.prevent="$emit('close')"
              >Cancel</a>
              <a
                href="#"
                class="btn btn-primary ml-2"
                @click.prevent="editFile"
              >
                Rename
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {meetingStore} from '@/store/__STORE_meetings';
import {mainStore} from '@/store/__STORE_main';
import {userStore} from '@/store/__STORE_user';

export default {
  props: {
    fileName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      file: this.fileName,
      errors: {},
    };
  },
  computed: {
    userInfo() {
      return userStore.__GETTERuserProfile;
    },
    editFileRecordingName() {
      return meetingStore.__GETTERshowEditFileName;
    },
  },
  created() {
    // eslint-disable-next-line
        $('body').addClass('f-modal-is-open');
  },
  destroyed() {
    // eslint-disable-next-line
        $('body').removeClass('f-modal-is-open');
  },
  methods: {
    editFile() {
      this.errors = {};

       const regex = /^[a-zA-Z0-9]+$/; // Regex pattern for only numbers and text
      if (!this.file.length) {
        this.errors = {file: ['The file name is required']};
        return;
      } else if (!regex.test(this.file)) {
        this.errors = {file: ['The file name should contain only numbers and text']};
        return;
      }

      mainStore.setLoading();
      const payload = {
        file_name: this.file,
        recording_file_id: this.editFileRecordingName.id,
        user_id: this.userInfo.id,
      };
      meetingStore.renameFile(payload)
          .then(() => {
            this.file = '';
            this.$toast.success('File rename successfully!');
            this.$emit('close');
            // window.location.reload();
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
              if (err.response.status === 422) {
                if (err.response.data.errors) {
                  this.errors = err.response.data.errors;
                } else {
                  this.errors = [];
                }
              }
          });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-input {
  font-size: 1.5rem;
}
</style>
